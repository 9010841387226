import styled from 'styled-components'
import { Box, SpanStyled, TextStyled } from './Elements';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}


export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='18px' />
                )}
                <SpanStyled weight={500} color='var(--drColor)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}
export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <SpanStyled weight={500} color='var(--drColor)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='18px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex ${props.desc ? 'align-items-start' : 'align-items-center'}`}>
        <Box className='d-flex align-items-center justify-content-center'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <TextStyled className='mb-0' weight={400} size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--textgrey)' : 'var(--textgrey)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </TextStyled>
            {props.desc ?
                <SpanStyled className='text-uppercase' color={'var(--textgrey)'} size='12px' weight={300}>
                    {props?.desc}
                </SpanStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    border: none;
    // box-shadow: 3px 3px 10px #e3e3e394;
    border-radius: 8px;
    height: 43px;
    color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    box-shadow: 3px 3px 10px #e3e3e3e0;
    -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ""};
  button {
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ""};
    background:${props => props.bg ? props.bg : "var(--gradientBlue)"};
    color: ${props => props.color ? props.color : "var(--white)"};
    padding: ${props => props.padding ? props.padding : "10px 18px"};
    border: ${props => props.border ? props.border : "none"};
    outline: ${props => props.border ? props.border : "none"};
    border-radius: ${props => props.radius ? props.radius : "8px"};
    box-shadow: ${props => props.shadow ? props.shadow : ""};
    gap: 8px;
    margin:0 2px;
    transition: all 0.5s ease;
    svg{
        [stroke]{
            stroke: ${props => props.stroke ? props.stroke : "var(--white)"} ;
        }
        [fill]{
            fill: ${props => props.fill ? props.fill : "var(--white)"};
        }
    }
    &:hover{
        opacity: 0.7 ;
    }
  }
`
export const ButtonWrapper = styled.div`
  button {
    display: flex;
    align - items: center;
    background-color:${props => props.bg ? props.bg : "transparent"};
    color: ${props => props.color ? props.color : "var(--white)"};
    padding: ${props => props.padding ? props.padding : "6px 16px"};
    border: ${props => props.border ? props.border : "1px solid var(--white)"};
    border-radius: ${props => props.radius ? props.radius : "8px"};
    background: transparent;
    gap: 8px;
    transition: all 0.5s ease;
    svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
     
    &:hover{
        background-color: var(--themeColor);
        border: 1px solid var(--themeColor);
        color: var(--white);
        svg{
            [stroke]{
                stroke: var(--white);
            }
            [fill]{
                fill: var(--white);
            }
        }
    }
}
`