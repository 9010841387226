import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, LazyImage, SpanStyled } from '../Elements'
import { Facebook, Footer1, Footer2, FooterLogo, Instagram, Logo, QRCode, Twitter } from '../AllImages'
import { Link } from 'react-scroll'
import { NavDataConfig } from '../../components/sliderData/Data'
import ChangeButton from '../../Languages/ChangeButton'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation();
    const date_year = new Date();
    return (
        <FooterWapper className='container-fluid footer-bg'>
            <Container>
                <Box className='footer-vector'>
                    <LazyImage className='footer-vector-img' src={Footer1} />
                    <LazyImage className='footer-vector-img' src={Footer2} />
                </Box>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-container'>
                        {/* <Box>
                            <LazyImage src={FooterLogo} />
                        </Box> */}
                        <Box className=' Footer-social-links'>
                            <Box className='d-flex flex-column gap-2'>
                                <SpanStyled className='vatTxt' size='14px' color='var(--white)' transform='capitalize'>{t('VAT_Number')}: 310673821900003</SpanStyled>
                                {/* <Box className='footer-list'>
                                    {NavDataConfig.map((item, key) => (
                                        <Box key={key}>
                                            <Link to={item.to}>{t(item.label)}</Link>
                                        </Box>
                                    ))}
                                </Box> */}
                                <SpanStyled className='copyRightDes' size='14px' color='var(--white)' transform='capitalize'>{t('All_Rights_Reserved')} &copy; {date_year.getFullYear()} {t('AlBarrak')}</SpanStyled>
                            </Box>
                            <Box className='d-flex align-items-center gap-4'>
                                <Box><Facebook /></Box>
                                <Box><Twitter /></Box>
                                <Box><Instagram /></Box>
                            </Box>
                        </Box>
                        <Box className='d-flex qr-code gap-2'>
                            {/* <Box className=' QR-code-text'>
                                <SpanStyled size='16px' color='var(--white)' weight="regular" transform='capitalize'>{t('Scan_QR')}</SpanStyled>
                                <SpanStyled size='16px' color='var(--white)' weight="bold" transform='capitalize'>{t('Commercial_Certificate')}</SpanStyled>
                            </Box> */}
                            <LazyImage src={QRCode} />
                            <LazyImage src={FooterLogo} />
                        </Box>
                    </Box>
                </Row>
                <SpanStyled className='copyRightRes' size='14px' color='var(--white)' transform='capitalize'>{t('All_Rights_Reserved')} &copy; {date_year.getFullYear()} {t('AlBarrak')}</SpanStyled>
            </Container>
            <ChangeButton />
        </FooterWapper>
    )
}
const FooterWapper = styled.footer`
    position:relative;
    padding: 45px 0 25px;
.footer-vector{
    position: absolute;
    top: -80px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.footer-list{
    display:flex;  
    align-items: center;
    gap: 35px;
    & a{
        color:var(--white);
        padding-bottom: 5px;
        &:hover, &.active{
            color:var(--themeColor);
            border-bottom: 2px solid var(--themeColor);
        }
    }
}
.QR-code-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

`
export default Footer
