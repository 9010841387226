import React from 'react'
import styled from 'styled-components'
import { Vedio } from '../../../ui/AllImages'
import { useTranslation } from 'react-i18next'
import { Box, SpanStyled } from '../../../ui/Elements'

const ServiceVedio = () => {
    const { t } = useTranslation()
    return (
        <VedioWapper className='videoContainer'>
            <Box className='vedio-card'>
                <video autoPlay muted loop>
                    <source src={Vedio} type="video/webm" />
                </video>
                <SpanStyled className='text-center' size='36px' weight='bold' color='var(--white)' transform='capitalize' >{t('Get_Back')}</SpanStyled>
            </Box>
        </VedioWapper >
    )
}
const VedioWapper = styled.div`
    margin-top:30px;
    margin-bottom:30px;
    border-radius: 16px;
    overflow: hidden;
    .vedio-card{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 400px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        z-index: 1;
     }
}
`

export default ServiceVedio
