
import Banner from './banner/Banner'
import About from './about/About'
import Services from './services/Services'
// import SpareParts from './parts/SpareParts'
import Brands from './brands/Brands'
import Contact from './contactUs/Contact'

const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Services />
            {/* <SpareParts /> */}
            <Brands />
            <Contact />
        </>
    )
}

export default Index
