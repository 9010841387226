import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { AboutArabic, AboutEngin, ArrowRight } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import AboutCard from './AboutCard'
import { useTranslation } from 'react-i18next'

const About = () => {

    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966500519005';
        const message = 'Hello, this is Al-barrak Trading';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <AboutWapper className='container-fluid about-bg common-space' id='about'>
                <Container>
                    <Row className='about-text-card'>
                        <Col lg={6} md={12} className='about-img about-English'>
                            <LazyImage src={AboutEngin} />
                        </Col>
                        <Col lg={6} md={12} className='about-arabic'>
                            <LazyImage src={AboutArabic} />
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='position-relative'>
                                <SpanStyled className='subHeading' color='var(--themeColor)' size='20px' weight='bold'>{t('Discover_heart')} </SpanStyled>
                                <MainHeading className='main-heading mb-3'>{t('AlBarrak')}</MainHeading>
                                <TextStyled>{t('Welcome_to_AlBarrak')}</TextStyled>
                                <SpanStyled color='var(--darkBlue)' size='16px' weight='bold'>{t('Our_Values')}</SpanStyled>
                                <TextStyled>{t('AlBarrak_values')}</TextStyled>
                                <ContactButton className='content-btn' icon={ArrowRight} label={t('Contact_Us')} onClick={WhatsApp} />
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </AboutWapper>
            <AboutCard />
        </>
    )
}
const AboutWapper = styled.section`
.about-text-card{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .subHeading{
        position: absolute;
        top: -8px;
        letter-spacing: 0.5px;
    }
}
`
export default About
