import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '../Elements'
import { HeadPhone, Logo } from '../AllImages'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../CustomElements'
import { NavDataConfig } from '../../components/sliderData/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966500519005';
        const message = 'Hello, this is Al-barrak Trading';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <HeaderContainer className={`${isSticky ? 'sticky' : ''} container-fluid py-3`}>
                <Container>
                    <Row>
                        <Box className='nav-bar'>
                            <Box className='logo' onClick={() => navigate('/')}>
                                < Logo />
                            </Box>
                            <Box className='nav-bar-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key} >
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" >{t(item.label)} </Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='d-flex align-items-center gap-2'>
                                <ButtonWithIcon className="headerWhatsapp" icon={HeadPhone} label='+966 500519005' onClick={WhatsApp} />
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>

                        </Box>
                    </Row>
                </Container>
            </HeaderContainer >
        </>
    )
}
const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list a{
        color:var(--white);
        padding-bottom: 12px;
        
    }
    .logo{
        cursor:pointer;
    }
    

}
`

export default Header
