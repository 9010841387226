import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ContactButton, InputWithIcon } from '../../../ui/CustomElements'
import { Box, LabelStyled, LazyImage, MainHeading, TextStyled } from '../../../ui/Elements'
import { ArrowRight, FormEmail, FormPhone, Message, Subject, SuccessLogo, User } from '../../../ui/AllImages'
import Model from '../../model/Model'
import axios from 'axios'


const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const getForm = async () => {
        try {
            const response = await axios.post("https://dev.albarraktrd.sa/api/public/api/contact-us", { ...formData, language: i18n.language })
            if (!response.data) {
                throw new Error('Response error plz check the response');
            }
            else {
                handleShowSuccessModal();
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    subject: "",
                    message: "",
                });
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const [Errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    const FormHandle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData((list) => {
            return { ...list, [name]: value }
        })

        setErrors((errors) => {
            return { ...errors, [name]: '' };
        });
    }

    const validateForm = () => {
        const errors = {};
        if (formData.name.trim() === "") {
            errors.name = t('Name_is_required');
        }
        if (formData.subject.trim() === "") {
            errors.subject = t('subject_is_required');
        }
        if (formData.message.trim() === "") {
            errors.message = t('message_is_required');
        }
        if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
            errors.email = t('Invalid_email_address');
        }
        if (formData.phone.trim() === "" || !isValidPhone(formData.phone)) {
            errors.phone = t('Invalid_phone_number');
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isValidPhone = (phone) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            getForm();
            console.log(formData);
        }
    };
    return (
        <ContacFormWapper>
            <Box>
                <Box className='d-flex flex-column justify-content-center gap-3 text-center'>
                    <MainHeading size='24px'>{t('Let_Connect')}</MainHeading>
                    <TextStyled>{t('Let_Connect_heading')}</TextStyled>
                </Box>
                <Form onSubmit={handleSubmit}>
                    <Row className='py-3 mb-5'>
                        <Col lg={6} md={12} className='pb-2'>
                            <LabelStyled className='py-2'>{t('Name')}</LabelStyled>
                            <InputWithIcon type='text' name='name' value={formData.name} placeholder={t('Name')} icon={User} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.name}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='pb-2'>
                            <LabelStyled className='py-2'>{t('Email')}</LabelStyled>
                            <InputWithIcon type='text' name='email' value={formData.email} placeholder={t('Email')} icon={FormEmail} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.email}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='pb-2'>
                            <LabelStyled className='py-2'>{t('Phone')}</LabelStyled>
                            <InputWithIcon type='text' name='phone' value={formData.phone} placeholder={t('Phone')} icon={FormPhone} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.phone}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='pb-2'>
                            <LabelStyled className='py-2'>{t('Subject')}</LabelStyled>
                            <InputWithIcon type='text' name='subject' value={formData.subject} placeholder={t('Subject')} icon={Subject} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.subject}</TextStyled>
                        </Col>
                        <Col md={12} className='pb-2'>
                            <LabelStyled className='py-2'>{t('Message')}</LabelStyled>
                            <Box className='textarea-box'>
                                <Box className='text-icon'>
                                    <Message />
                                </Box>
                                <textarea cols="" rows="6" name='message' value={formData.message} placeholder={t('Message')} onChange={FormHandle}></textarea>
                                <TextStyled className="error-message">{Errors.message}</TextStyled>
                            </Box>
                        </Col>
                        <ContactButton type="submit" className="mb-4 mt-2" content='end' icon={ArrowRight} label={t('Send')} />
                    </Row>
                </Form>
            </Box>
            <Model />
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <LazyImage src={SuccessLogo} />
                    <TextStyled>{t('Message_Sent_successfully')}</TextStyled>
                </Modal.Body>
                <Modal.Footer>
                    <ContactButton className="my-1" content='end' label={t('Close')} onClick={handleCloseSuccessModal} />
                </Modal.Footer>
            </Modal>
        </ContacFormWapper >
    )
}
const ContacFormWapper = styled.div`
    margin:30px 0;

`

export default ContactForm
