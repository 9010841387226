import Map from './Map'
import React from 'react'
import styled from 'styled-components'
import ContactForm from './ContactForm'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ContactVector1, ContactVector2 } from '../../../ui/AllImages'
import { Box, LazyImage, MainHeading, TextStyled } from '../../../ui/Elements'


const Contact = () => {
    const { t } = useTranslation();
    return (
        <ContactWapper className=' my-5' id='contact'>
            <Box className='content-vector'>
                <LazyImage className='contact-vector-img' src={ContactVector1} />
                <LazyImage className='contact-vector-img' src={ContactVector2} />
            </Box>
            <Container>
                <Box className='contact-card'>
                    <Box className='content-heading d-flex flex-column align-items-center justify-content-center gap-3'>
                        <MainHeading className='main-heading stripCenter'>{t('Contact_Us')}</MainHeading>
                        <TextStyled className='mb-0'>{t('Contact_heading')}</TextStyled>
                    </Box>
                    <Map />
                </Box>
                <ContactForm />
            </Container>
        </ContactWapper>
    )
}
const ContactWapper = styled.section`
    position:relative;
    .content-vector{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
.content-heading{
    position:relative;
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 0 20px 30px;
    &:before{
        content: '';
        position: absolute;
        top: 40px;
        width: 100%;
        height: 270px;
        border-radius: 20px;
        border: 1px solid #D5D5D5;
        border-bottom: none;
        z-index: -1;
    }
}
.main-heading{
    position: relative;
    padding: 0 10px;
    position: relative;
    background: var(--white);
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        // left: 130px;
        width: 80px;
        height: 4px;
        background: var(--gradientBlue); 
    }
    
}
`

export default Contact
