import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyImage } from "../ui/Elements";
import styled from "styled-components";
import { Arabic, English, Whatsapp } from "../ui/AllImages";
import Cookies from "js-cookie";

const ChangeButton = () => {
  const WhatsApp = () => {
    const phoneNumber = "+966500519005";
    const message = "Hello, this is Al-barrak Trading";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };
  const { i18n } = useTranslation();
  const [isEnglishSelected, setIsEnglishSelected] = useState(
    Cookies.get("lang") === "en" ? false : true
  );

  const changeLanguageAndDirection = (lng, dir) => {
    i18n.changeLanguage(lng);
    document.documentElement.dir = dir;
    document.body.classList.toggle("rtl", dir === "rtl");
    Cookies.set("lang", lng);
    Cookies.set("dir", dir);
  };
  const handleLanguageChange = (lng, dir) => {
    changeLanguageAndDirection(lng, dir);
    setIsEnglishSelected(!isEnglishSelected);
    Cookies.set("lang", lng, { expires: 60, path: "/" });
    Cookies.set("dir", dir, { expires: 60, path: "/" });
  };

  useEffect(() => {
    const savedLang = Cookies.get("lang");
    const savedDir = Cookies.get("dir");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
    else {
      // Set default language to Arabic if not saved in cookies
      i18n.changeLanguage("ar");
      Cookies.set("lang", "ar", { expires: 60, path: "/" });
    }
    if (savedDir) {
      document.documentElement.dir = savedDir;
      document.body.classList.toggle("rtl", savedDir === "rtl");
    }
    else {
      // Set default direction to RTL if not saved in cookies
      document.documentElement.dir = "rtl";
      document.body.classList.toggle("rtl", true);
      Cookies.set("dir", "rtl", { expires: 60, path: "/" });
    }
  }, []);

  return (
    <>
      <ButtonBox>
        <span className="whatsapp" onClick={WhatsApp}>
          <LazyImage src={Whatsapp} />
        </span>
        {isEnglishSelected ? (
          <span
            className="change-Btn"
            onClick={() => handleLanguageChange("en", "ltr")}
          >
            <LazyImage src={English} className="english_icon" alt="USA"/>
          </span>
        ) : (
          <span
            className="change-Btn"
            onClick={() => handleLanguageChange("ar", "rtl")}
          >
            <LazyImage src={Arabic} className="arabic_icon"  alt="Arabic"/>
          </span>
        )}
      </ButtonBox>
    </>
  );
};
const ButtonBox = styled.div`
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  .whatsapp {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .change-Btn {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .english_icon {
    // width: 50px;
    // height: 50px;
    border-radius: 50%;
    // margin: 30px 15px;
    cursor: pointer;
    z-index: 1;
  }
  .arabic_icon {
    // width: 70px;
    // height: 70px;
    border-radius: 50%;
    // margin: 20px 15px;
    cursor: pointer;
    z-index: 1;
  }
`;

export default ChangeButton;
