import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { Box, MainHeading, TextStyled } from '../../../ui/Elements'
import ServiceSlide from './ServiceSlide'
import ServiceVedio from './ServiceVedio'
import ServiceBrand from './ServiceBrand'
import { useTranslation } from 'react-i18next'

const Services = () => {
    const { t } = useTranslation()
    return (
        <ServicesWrapper className='container-fluid common-space' id='services'>
            <Container>
                <Box className='d-flex flex-column align-items-center justify-content-center text-center gap-3 my-3'>
                    <MainHeading className='py-2 main-heading stripCenter'>{t('What_We_Offer')}</MainHeading>
                    <TextStyled className='py-2'>{t('our_company')}</TextStyled>
                </Box>
                <ServiceSlide />
                <ServiceBrand />
                <ServiceVedio />
            </Container>
        </ServicesWrapper>
    )
}
const ServicesWrapper = styled.section`
// .main-heading::before{
//     content: '';
//     position: absolute;
//     left: 10px;
//     width: 70px;
//     height: 4px;
//     background: var(--gradientBlue);
//     bottom: -5px;
// }
.rtl{
    &.main-heading::before{
        right: 15px;
        left:auto;
    }
} 
`

export default Services
