import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { Box } from '../../../ui/Elements'
import { useTranslation } from 'react-i18next'
import { TextWithIcon } from '../../../ui/CustomElements'
import { Email, MapIcon, Phone } from '../../../ui/AllImages'

const Map = () => {
    const { t } = useTranslation();
    const mapLocation = {
        map1: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.362105824797!2d43.885177999999996!3d26.4117888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f57637bbd074f%3A0x9a4855130781be61!2z2KfZhNio2LHYp9mDINmE2YLYt9i5INi62YrYp9ixINin2YTYtNin2K3Zhtin2Kog2YjYp9mE2YXYudiv2KfYqiDYp9mE2LLYsdin2LnZitip!5e0!3m2!1sen!2ssa!4v1699555765763!5m2!1sen!2ssa",
        map2: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3575.4861523419963!2d43.958079500000004!3d26.343133500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f59756525bc19%3A0x146afabe34df7d83!2sAlbarrak%20for%20diesel%20engine%20parts!5e0!3m2!1sen!2s!4v1699555838885!5m2!1sen!2s",
    }
    return (
        <MapWapper>
            <Row>
                <Col lg={6} md={12} className=' my-2'>
                    <Box className='location-card'>
                        <TextWithIcon icon={Phone} title='+966 500519005' />
                        <TextWithIcon icon={Email} title='info@albarraktrd.sa' />
                        <TextWithIcon icon={MapIcon} title={t('map1')} />
                        <Box>
                            <iframe src={mapLocation.map1} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Box>
                    </Box>
                </Col>
                <Col lg={6} md={12} className='my-2'>
                    <Box className='location-card'>
                        <TextWithIcon icon={Phone} title='+966 163247543' />
                        <TextWithIcon icon={Email} title='info@albarraktrd.sa' />
                        <TextWithIcon icon={MapIcon} title={t('map2')} />
                        <Box>
                            <iframe src={mapLocation.map2} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Box>
                    </Box>
                </Col>
            </Row>
        </MapWapper>
    )
}
const MapWapper = styled.div`
.location-card{
    background: white;
    border-radius: 20px;
    box-shadow:1px 1px 8px #ababab6e;
    padding: 25px 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
iframe{
    border-radius: 20px;
    width: 100%;
    height: 200px;
}
`

export default Map
