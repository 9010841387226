import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BrandDataConfig } from '../../sliderData/Data'
import { Box, LazyImage, MainHeading, TextStyled } from '../../../ui/Elements'
import { BrandArrowLeft, BrandArrowRight, BrandImg } from '../../../ui/AllImages'

const Brands = () => {
    const { t } = useTranslation();
    const settings = {
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        pauseOnHover: false,
        nextArrow: <BrandArrowRight />,
        prevArrow: <BrandArrowLeft />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <BrandWapper id='brands'>
            <Box className='brand-vector'>
                <LazyImage src={BrandImg} />
            </Box>
            <Container>
                <Box className='brand-card'>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center gap-4'>
                        <MainHeading className='main-heading stripCenter' color='var(--white)'>{t('Our_brands')}</MainHeading>
                        <TextStyled color='var(--white)'>{t('Our_brands_heading')}</TextStyled>
                    </Box>
                    <Box className='w-100'>
                        <Slider className='d-flex align-items-center justify-content-evenly' {...settings}>
                            {
                                BrandDataConfig.map((list, key) => (
                                    <Box key={key} className={`brand-slider`}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                </Box>
            </Container>
        </BrandWapper>
    )
}
const BrandWapper = styled.section`
    position:relative;
    .brand-vector{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -105px;
        width: 100%;
    
    }
    .main-heading{
        position: relative;
        &:before{
            content: '';
            position: absolute;
            // bottom: 0;
            // left: 120px;
            width: 100px;
            height: 4px;
            background: var(--white);
        }
    }
    .brand-slider{
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background: var(--white);
        height: 150px;
    }

`

export default Brands
