import { Banner1, Banner2, Client1, Client10, Client11, Client12, Client13, Client15, Client16, Client17, Client18, Client19, Client2, Client20, Client21, Client3, Client4, Client5, Client6, Client7, Client8, Client9, Parts1, ServiceImg1, ServiceImg2, ServiceImg3, ServiceImg4, ServiceImg5, ServiceImg6, ServiceImg7, ServiceImg8 } from "../../ui/AllImages";
import { LazyImage } from "../../ui/Elements";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'services',
        label: 'Services',
    },
    // {
    //     to: 'parts',
    //     label: 'SpareParts',
    // },
    {
        to: 'brands',
        label: 'Brands',
    },
    {
        to: 'contact',
        label: 'contactUs',
    },
]

export const bannerDataConfig = [
    {
        subHeading: 'AlBarrak',
        heading: 'Powering',
        heading2: 'Your_Journey',
        des: 'We_understand_that',
        label: 'Contact_Us',
        img: <LazyImage src={Banner1} />
    },
    {
        subHeading: 'Powering_Agriculture',
        heading: 'Our_Machinery',
        heading2: 'Your_Prosperity',
        des: 'We_understand_that',
        label: 'Contact_Us',
        img: <LazyImage src={Banner2} />,
    },
]

export const serviceDataConfig = [
    {
        heading: 'Mercedes_Agricultural_Machinery',
        img: <LazyImage src={ServiceImg7} />
    },
    {
        heading: 'Generator_Transformers',
        img: <LazyImage src={ServiceImg8} />
    },
    {
        heading: 'Central_Pivot_Sprinklers',
        img: <LazyImage src={ServiceImg6} />
    },
    {
        heading: 'importSpareParts',
        img: <LazyImage src={ServiceImg5} />
    },
    {
        heading: 'Import_Agricultural',
        img: <LazyImage src={ServiceImg4} />
    },
    {
        heading: 'Truck_Parts',
        img: <LazyImage src={ServiceImg3} />
    },
    {
        heading: 'Wholesale',
        img: <LazyImage src={ServiceImg2} />
    },
    {
        heading: 'Maintenance',
        img: <LazyImage src={ServiceImg1} />
    },

]

export const Menu = [
    {
        id: 1,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_Chassis",
        button: 'Order_Now',
    },
    {
        id: 2,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_Chassis",
        button: 'Order_Now',
    },
    {
        id: 3,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Brake_System_Parts",
        button: 'Order_Now',
    },
    {
        id: 4,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Brake_System_Parts",
        button: 'Order_Now',
    },
    {
        id: 5,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_Air",
        button: 'Order_Now',
    },
    {
        id: 6,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_Air",
        button: 'Order_Now',
    },
    {
        id: 7,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_for_Engine",
        button: 'Order_Now',
    },
    {
        id: 8,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Parts_for_Engine",
        button: 'Order_Now',
    },
    {
        id: 9,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Engine_Mounting_Parts",
        button: 'Order_Now',
    },
    {
        id: 10,
        img: <LazyImage src={Parts1} alt="" />,
        heading: "Camshaft",
        category: "Engine_Mounting_Parts",
        button: 'Order_Now',
    },

];

export const BrandDataConfig = [
    {
        img: <LazyImage src={Client1} />
    },
    {
        img: <LazyImage src={Client2} />
    },
    {
        img: <LazyImage src={Client3} />
    },
    {
        img: <LazyImage src={Client4} />
    },
    {
        img: <LazyImage src={Client5} />
    },
    {
        img: <LazyImage src={Client6} />
    },
    {
        img: <LazyImage src={Client7} />
    },
    {
        img: <LazyImage src={Client8} />
    },
    {
        img: <LazyImage src={Client9} />
    },
    {
        img: <LazyImage src={Client10} />
    },
    {
        img: <LazyImage src={Client11} />
    },
    {
        img: <LazyImage src={Client12} />
    },
    {
        img: <LazyImage src={Client13} />
    },
    {
        img: <LazyImage src={Client15} />
    },
    {
        img: <LazyImage src={Client16} />
    },
    {
        img: <LazyImage src={Client17} />
    },
    {
        img: <LazyImage src={Client18} />
    },
    {
        img: <LazyImage src={Client19} />
    },
    {
        img: <LazyImage src={Client20} />
    },
    {
        img: <LazyImage src={Client21} />
    },



]