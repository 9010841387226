import React from 'react'
import Slider from 'react-slick'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Box, SpanStyled } from '../../../ui/Elements'
import { serviceDataConfig } from '../../sliderData/Data'
import { NextBtn, PreviousBtn, } from '../../../ui/AllImages'

const ServiceSlide = () => {
    const { t } = useTranslation()
    const settings = {
        infinite: true,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <NextBtn />,
        prevArrow: <PreviousBtn />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <ServiceSlideWapper>
            <Box className='serviceSliderWapper py-3'>
                <Slider className='d-flex align-items-center' {...settings}>
                    {
                        serviceDataConfig.map((list, key) => (
                            <Box key={key} className={`SlideBox`}>
                                <SpanStyled className='gradientGrey' size='18px' transform='capitalize'>{t(list.heading)}</SpanStyled>
                                {list.img}
                            </Box>
                        ))
                    }
                </Slider>
            </Box>
        </ServiceSlideWapper>

    )
}
const ServiceSlideWapper = styled.div`
.SlideBox {
    position: relative;
    height: 200px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
}
`

export default ServiceSlide
