import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { bannerDataConfig } from '../../sliderData/Data'
import { ArrowRight, ScrollDownArrow } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../ui/Elements'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'


const Banner = () => {
    const WhatsApp = () => {
        const phoneNumber = '+966500519005';
        const message = 'Hello, this is Al-barrak Trading';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const SliderSetting = {
        autoplay: true,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplaySpeed: 3000,
    }
    return (
        <>
            <BannerWapper className='container-fluid banner-bg' id='home'>
                <Container>
                    <Box className='banner-wapper-card'>
                        <Slider {...SliderSetting}>
                            {
                                bannerDataConfig.map((list, key) => (
                                    <Row key={key} className='banner-card'>
                                        <Col lg={6} md={12} className='banner-text-card'>
                                            <Box className='d-flex flex-column text-uppercase ' >
                                                <SpanStyled className='sub-heading' size='20px' family='var(--bold)' color='var(--white)' spacing='7px'>{t(list.subHeading)}</SpanStyled>
                                                <HeadingStyled size='54px' className='gradientBlue Main-heading resp' family='var(--bold)'>{t(list.heading)}</HeadingStyled>
                                                <HeadingStyled className='resp' size='54px' color='var(--white)'>{t(list.heading2)}</HeadingStyled>
                                                <TextStyled color='var(--white)'>{t(list.des)}</TextStyled>
                                            </Box>
                                            <ContactButton className='content-btn' icon={ArrowRight} label={t(list.label)} onClick={WhatsApp} />
                                        </Col>
                                        <Col lg={6} md={12}>
                                            {list.img}
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Slider>
                    </Box>
                </Container>
                
                <Link to='about' spy={true} smooth={true} offset={-60} duration={500} ><ScrollDownArrow className='scrollDown' /></Link>
            </BannerWapper >
        </>
    )
}
const BannerWapper = styled.section`
    position: relative;
    .banner-wapper-card{
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 75px;
    }
    .banner-card{
        padding-top:70px;
        display: flex !important;
        // align-items: center;
        justify-content: space-between;
    }
    .banner-text-card{
        display: flex;
        flex-direction: column;
    }
    .scrollDown{
        position: absolute;
        bottom: 10px;
        right: 0;
        left: 0;
        margin: auto;
    }
`

export default Banner
