import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { BrandSlideImg1, TopBrand, TopBrand2, TopBrand3 } from '../../../ui/AllImages'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'

const ServieBrand = () => {
    const { t } = useTranslation()
    return (
        <ServiceBrandWapper className='container-fluid common-space brandFluid'>
            <Row>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6} md={12}>
                            <Box className='position-relative top-brand'>
                                <SpanStyled className='subHeading' color='var(--themeColor)' size='20px' weight='bold'>{t('Quality_Parts_from')}</SpanStyled>
                                <MainHeading className='main-heading mb-3'>{t('Top_Brands')}</MainHeading>
                                <TextStyled>{t('Top_Brands_Heading')}</TextStyled>
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='brand-quality'>
                                <Box className='brand-quality-card'><LazyImage src={TopBrand} /></Box>
                                <Box className='brand-quality-card'><LazyImage src={TopBrand2} /></Box>
                                <Box className='brand-quality-card'><LazyImage src={TopBrand3} /></Box>
                                <Box className='brand-quality-card'><LazyImage src={BrandSlideImg1} /></Box>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </ServiceBrandWapper>
    )
}
const ServiceBrandWapper = styled.div`
.brand-quality{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.subHeading {
    position: absolute;
    top: -10px;
    letter-spacing: 0.5px;
}

.brand-quality-card{
    height: 150px;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 8px #ababab6e;
    display: flex;
    align-items: center;
    justify-content: center;
}
`

export default ServieBrand
