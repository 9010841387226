import React from 'react'
import styled from 'styled-components'
import { Box, SpanStyled, TextStyled } from '../../../ui/Elements'
import { ContactButton } from '../../../ui/CustomElements'
import { AboutImg, ArrowRight } from '../../../ui/AllImages'
import { useTranslation } from 'react-i18next'


const AboutCard = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966500519005';
        const message = 'Hello, this is Al-barrak Trading';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <AboutCardWapper className='container my-5'>
            <Box className='about-card'>
                <Box className='about-card-text'>
                    <SpanStyled className='gradientGrey' size='36px' weight='bold'>{t('Driving_Excellence')}</SpanStyled>
                    <TextStyled color='var(--white)'>{t('Source_Quality')}</TextStyled>
                    <ContactButton bg='var(--white)' color='var(--themeColor)' stroke='var(--themeColor)' fill='var(--themeColor)' icon={ArrowRight} label={t('Order_Now')} onClick={WhatsApp} />
                    <span className='about-card-img'>
                        <img className='about-img' src={AboutImg} />
                    </span>
                </Box>

            </Box>
        </AboutCardWapper>
    )
}
const AboutCardWapper = styled.div`
.about-card{
    position: relative;
}
`

export default AboutCard
